<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearchBar v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="10">
            <el-form-item prop="content" label="题目描述">
              <el-input v-model="queryParams.content" placeholder="请输入题目描述" clearable/>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="8" :md="6" :lg="6" :xl="6">
            <el-form-item prop="isTop" label="题目类型">
              <el-select v-model="queryParams.type" clearable placeholder="请选择题目类型">
                <el-option
                    v-for="dict in dict.question_type"
                    :key="dict.dictValue"
                    :label="dict.dictLabel"
                    :value="dict.dictValue"/>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :xs="24" :sm="12" :md="10" :lg="10" :xl="8">
            <el-form-item label="创建时间">
              <el-date-picker
                  v-model="dateRange"
                  type="daterange"
                  align="right"
                  unlink-panels
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </TableSearchBar>
    </el-collapse-transition>

    <div>
      <el-button size="mini" type="success" @click="handleSave">添 加</el-button>
      <el-button size="mini" type="primary" @click="showSearch = !showSearch">搜 索</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'createTime', order: 'descending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="content" label="题目描述" show-overflow-tooltip/>

        <el-table-column label="题目类型" width="200">
          <template slot-scope="scope">
            <dict-tag :options="dict.question_type" :value="scope.row.type"></dict-tag>
          </template>
        </el-table-column>

        <el-table-column prop="createTime" label="创建时间" width="200" sortable="custom"/>

        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <!--<el-button type="text" @click="handleView(scope.row)">查 看</el-button>-->
            <el-button size="large" type="text" @click="handleUpdate(scope.row)">修改</el-button>
            <el-button size="large" type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" @cancel="handleClose" width="920px" top="100">
      <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
        <el-row type="flex" justify="center">
          <el-col :span="12">
            <div style="width: 100%">

              <el-form-item label="id" prop="id" v-show="false">
                <el-input type="number" v-model="formData.id" />
              </el-form-item>

              <el-form-item label="option" prop="option" v-show="false">
              </el-form-item>

              <el-form-item prop="type" label="题目类型">
                <el-select v-model="formData.type" clearable placeholder="请选择题目类型">
                  <el-option
                      v-for="dict in dict.question_type"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"/>
                </el-select>
              </el-form-item>

              <el-form-item label="题目描述" prop="content">
                <el-input
                    type="textarea"
                    :rows="10"
                    v-model="formData.content"
                    placeholder="请输入题目描述"
                    maxlength="200"
                    show-word-limit
                    clearable/>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="12">

            <el-form-item label="正确选项" prop="isCorrect" v-if="currentType">
              <el-select v-model="formData.isCorrect[0]" muplaceholder="请选择正确选项" v-if="Number(currentType)===1">
                <el-option
                    v-for="(item, index) in formData.option"
                    :key="optionPrefix[index]"
                    :label="`选项 ${optionPrefix[index]}`"
                    :value="index">
                </el-option>
              </el-select>

              <el-select v-model="formData.isCorrect" multiple v-if="Number(currentType) === 2"
                         placeholder="请选择正确选项">
                <el-option
                    v-for="(item, index) in formData.option"
                    :key="optionPrefix[index]"
                    :label="`选项 ${optionPrefix[index]}`"
                    :value="index">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item :label="`选项 ${optionPrefix[index]}`" v-for="(item, index) in formData.option" :key="index">
              <el-row>
                <el-col :span="21">
                  <el-input :placeholder="`请输入选项 ${optionPrefix[index]}`" v-model="item.content"
                            clearable></el-input>
                </el-col>
                <el-col :span="3" v-if="Number(currentType) === 1 ? formData.option.length > 2 : formData.option.length > 3">
                  <div class="option-remove">
                    <i @click="handleRemoveOption(index)" style="padding-top: 5px" class="el-icon-remove"></i>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>

            <el-form-item v-if="formData.option.length <= optionPrefix.length - 1">
              <el-row>
                <el-col :span="21">
                  <div class="option-add">
                    <i @click="handleAddOption" class="el-icon-circle-plus"></i>
                  </div>
                </el-col>
              </el-row>
            </el-form-item>

          </el-col>
        </el-row>
      </el-form>
    </CommonDialog>

  </div>
</template>

<script>
import TableSearchBar from "@/components/TableSearchBar/TableSearchBar.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import DictTag from "@/components/DictTag/index.vue";

export default {
  name: "index",
  dicts: ['question_type'],
  components: {DictTag, CommonDialog, Pagination, TableSearchBar},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        name: '',
        type: null,
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      optionPrefix: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'],
      formData: {
        id: null,
        content: '',
        type: '',
        isCorrect: [],
        option: [{content: ''}, {content: ''}],

      },
      //option: [{content: ''}, {content: ''}],
      rules: {
        content: [
          {required: true, message: '请输入题目描述', trigger: 'blur'},
          {min: 1, max: 200, message: '1~200字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择题目类型', trigger: ['change', 'blur']},
        ],
        isCorrect: [
          {required: true, validator: this.validateIsCorrect, trigger: ['change', 'blur']},
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
    }
  },

  computed: {
    currentType() {
      return this.formData.type
    },
  },

  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.question.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    handleAddOption() {
      this.formData.option.push({})
    },

    handleRemoveOption(index) {
      this.formData.option.splice(index, 1)
      this.formData.isCorrect = []
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
      console.log(this.formData)
    },

    handleUpdate(row) {
      this.$api.question.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData.id = res.id
          this.formData.type = res.type
          this.formData.content = res.content
          let option = []
          let isCorrect = []

          res.optionList.forEach((item, index) => {
            option.push({content: item.content})
            if (item.isCorrect) {
              isCorrect.push(index)
            }
          })

          this.formData.isCorrect = isCorrect
          this.formData.option = option
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除 “ <b class="color-danger">${row.content}</b> ”？`
      }).then(() => {
        this.$api.question.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSubmitConfirm() {
      if (!this.answerCorrect()) {
        this.$refs.dialog.stopLoading()
        return
      }

      this.$refs['form'].validate((valid) => {
        if (valid) {

          let data = this.handleData()

          if (data.id) {
            this.$api.question.update(data).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.question.save(data).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      console.log('handleReset')
      this.dateRange = []
      this.handleSearch()
    },

    validateIsCorrect(rule, value, callback) {
      if (Number(this.currentType) === 1 && value.length < 1) {
        callback(new Error('请选择正确选项'));
      } else if (Number(this.currentType) === 2 && value.length < 2) {
        callback(new Error('题目类型为多选题时，正确选项不能低于2个'));
      } else {
        callback();
      }
      callback();

    },

    answerCorrect() {
      if (this.formData.option.filter(v => !v.content).length) {
        this.$message.warning("可选项的内容不能为空")
        return false
      }

      if (Number(this.currentType) === 2 && this.formData.option.length < 3) {
        this.$message.warning("题目类型为多选题时，可选项不能低于3个")
        return false
      }
      return true
    },

    handleData() {
      let optionList = []

      this.formData.option.forEach((item, index) => {
        optionList.push({
          content: item.content,
          isCorrect: this.formData.isCorrect.includes(index)
        })
      })

      return {
        id: this.formData.id,
        type: this.currentType,
        content: this.formData.content,
        optionList: optionList
      }
    }
  }
}
</script>

<style>

</style>

<style scoped>
.option-add {
  font-size: 30px;
  color: #3f9dfd;
  display: flex;
  justify-content: center
}

.hover {
  opacity: 0.7;
}

.option-add:hover {
  opacity: 0.7;
}

.option-remove {
  width: 100%;
  font-size: 30px;
  color: #f36b6b;
  display: flex;
  justify-content: center;
  align-items: center
}

.option-remove:hover {
  opacity: 0.7;
}

::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 320px;
  height: 178px;
  display: block;
}
</style>
